import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 8-8-8\\@70% 1RM`}</p>
    <p>{`Hamstring Curl 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Hang Power Clean (135/95)`}</p>
    <p>{`*`}{`2 Wall Walks after each exercise.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Memorial Day Murph will be at CrossFit Louisville East tomorrow at
10:00am.  All are invited and the wod can be scaled as needed for
anyone.  All other classes and open gym are cancelled today at both
locations so come out to East at 10:00am and join in the fun/pain!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      